@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap);
* {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  min-width: 100vw;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  overflow-x: hidden;
}

p {
  font-size: 0.8rem;
}
